#mixins() {
  .clear-fix() {
    &:after {
      content: '';
      display: table;
      clear:both;
    }
  }

  .box-cover() {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .no-select() {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }

}