/* All Lists */
#list-mixin() {
    .day-color(@hex) {
        border-left: 4px solid @hex;

        .number-point {
            border-color: @hex;
        }
    }
}

div.list-item {
    margin: 5px 10px;
    border: 1px solid rgb(169, 169, 169);
    border-radius: 6px;
    border-left: 4px solid #9900ff;
    display: flex;
    padding: 3px;

    &.active {
        background: rgba(124, 255, 0, .4);
    }

    &.Tue {
        #list-mixin.day-color(#fdca2e);
    }

    &.Wed {
        #list-mixin.day-color(#fc3768);
    }

    &.Thu {
        #list-mixin.day-color(#6aca25);
    }

    &.Fri {
        #list-mixin.day-color(#6bcdfd);
    }

    &.Sat {
        #list-mixin.day-color(#fd6ccc);
    }

    &.Sun {
        #list-mixin.day-color(#c7c7c9);
    }

    &.inactive {
        opacity: .5;
        color: #000000;
    }

    &.signed-up {
        background-color: #5bff28;
    }

    .aside {
        order: 1;
        border-right: 1px solid rgb(169, 169, 169);
        padding: 5px;
    }

    .body {
        order: 2;
        flex-grow: 1;
        text-align: left;
        padding: 5px;

        h1 {
            text-align: left;
        }
    }

    .notes {
        order: 3;
        padding: 5px;
        border-left: 1px solid #a9a9a9;
        display:flex;
        align-items: center;

        img {
            max-height: 30px;
            height: 30px;
        }
    }



    .phone {
        order: 4;
        padding: 5px;
        border-left: 1px solid #a9a9a9;
        display:flex;
        align-items: center;
    }

    .body img,
    .phone img {
        max-height: 30px;
        height: 30px;


    }

    .number-point {
        position: relative;
        border: 2px solid #9900ff;
        border-radius: 500px;
        width: 39px;
        height: 39px;
        margin: 1px;
        text-align: center;
        line-height: 39px;
        vertical-align: middle;
        font-size: 25px;
        font-weight: bold;
        color: rgb(169, 169, 169);

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 65%;
            filter: contrast(1%)
        }
    }

    &.appointment {

        &.no-flex {
            display: block !important;
            text-align: center;

            h2 {
                font-size: 30px;
            }
        }

        align-items: center;
        justify-content: center;

        line-height: 40px;
        vertical-align: middle;

        .aside {
            border-right: none;
        }

        .body {
            border-left: 1px solid #a9a9a9;
        }

        a {
            align-self: flex-start;
            flex-grow: 0;
            text-decoration: none;
            //display: flex;
            line-height: 40px;
            vertical-align: middle;
            display: inline-block;

            &.button {
                border-radius: 3px;
            }

            img {
                margin: 5px;
                display: inline-flex;
            }

            span {
                display: inline-flex;
                color: rgb(61, 59, 120);
                font-size: 20px;
            }
        }
    }
}

/* Multicoloured Lists */
.multicolor-list {
    div.list-item {
        .number-point {
            order: 3;
            margin: 6px;
        }

        .body {
            line-height: 40px;
            text-align: center;
            vertical-align: middle;
        }
    }

    .phone img {
        margin: 5px;
        display: inline-flex;
    }
}


/* Edit Bar */
.edit-bar {
    display: flex;
    padding: 5px;

    .info-box {
        flex-grow: 1;
        text-align: center;

        span {
            font-weight: bold;
            color: red;
        }
    }
}

.edit-bar .reorder, .edit-bar .info-box {
    border: 1px solid rgb(169, 169, 169);
    border-radius: 3px;
    padding: 5px 10px;
    margin: 5px;
    background-color: #fff;
}

.edit-bar .reorder {
    padding: 5px 15px;
    margin-left: 0;
    cursor: pointer;
}

#list {
    padding-left: 15px;
}
