@import 'vars';

input[type=text],
input[type=phone],
input[type=email],
input[type=password],
input[type=number],
textarea,
select,
label {
  display: block;
  border: 1px solid @borderColor;
  border-radius: 3px;
  padding: 5px 10px;
  width: 100%;
  margin: 4px 0;
}
