@import 'mixins';
@import 'header';
@import 'form';
@import 'list';
@import "appointment";
@import "footer";
@import "systemPrompt";

pre {
  white-space: pre !important;
}


html, body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #eaebef;
  font-size: 14px;
}

#app {
  padding-bottom: 45px;
}

h1, h2 {
  color: rgb(61, 59, 120);
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 20px;
}
h1 {
  font-weight:bold;
}

h2 {
  font-weight:normal;
}

main {
}

#login-form {
  padding: 10px;

  input[type=submit] {
    background-color: rgb(212, 47, 99);
    border:1px solid rgb(169, 169, 169);
    width:100%;
    padding: 15px;
    border-radius: 5px;
    color: rgb(255,255,255);
    font-weight: bold;
    font-size: 16px;
  }
}

.payg-logo {
  margin:34px auto;
  width:  100px;
  height: 100px;
  border-radius: 3000px;
  border:1px solid rgb(169, 169, 169);
  overflow: hidden;
  background-color: rgb(255,255,255);
  position:relative;

  img {
    width: 80%;
    position:absolute;
    top: 50%;
    left:50%;
    transform: translateY(-50%) translateX(-50%);

  }
}

.quick-link {
  cursor: pointer;
}

.button {
  #mixins.no-select;

  text-decoration: none !important;
  display: block;
  margin: 5px 20px;
  border-radius: 10px;
  color: #fff !important;
  border:1px solid #000;
  font-weight: bold;
  font-size: 22px;
  padding: 10px;
  text-align: center;
  cursor:pointer;


  &.green {
    background: green;
  }
  &.yellow {
    background: orange;
  }
  &.red {
    background: red;
  }
  &.blue {
    background: blue;
  }
  &.square {
    border-radius: 0;
  }
  &.purple {
    background: #8747a6;
  }
}

.loading {
  text-align: center;
  padding: 10px 0;
}

.mini-error {
  color: red;
  float:right;
}

.static-error {
    margin: 10px;
    padding: 10px;
    background: #f00;
    color: #fff;
    font-weight: bold;

}
