footer {
  z-index: 999999;
  position:fixed;
  right:0;
  bottom:0;
  left:0;
  height: 40px;
  background-color: rgb(255,255,255);
  border-top:1px solid rgb(169, 169, 169);

  a {
    position:relative;
    width:40px;
    height:40px;
    float:right;
    background-image: url(../assets/icons/Panic.png);
    background-size: cover;

    img {
      position: absolute;
      top:50%;
      left:50%;
      transform: translateY(-50%) translateX(-50%);
      width:65%;
    }
  }

  div {
    padding: 0 5px;
  }
}
main {
  padding-bottom:45px;
}