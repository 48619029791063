
header {
  border-bottom: 1px solid rgb(169, 169, 169);
  background-color: rgb(255, 255, 255);
  display: flex;
  height: 59px;

  nav {
    float:left;
    padding: 10px 5px 5px 5px;
    text-align: center;
    border-right: 1px solid rgb(169, 169, 169);
    order: 1;

    img {
      width: 20px;
      display: block;
      margin: 0 auto;
    }

    span {
      color: rgb(107, 105, 117);
      font-size: 16px;
    }
  }

  .header {
    order: 2;
    flex-grow: 1;
  }

  #tshooter {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid black;
    height: 59px;
    width: 50px;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    color: red;
  }
}