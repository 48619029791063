@import 'mixins';
.prompt-cover {
  #mixins.box-cover;

  position:fixed;
  background: rgba(255, 255, 255, .3);
  z-index: 100;
}
.system-prompt {
  padding: 5px;
  position:fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-height: 80%;
  overflow:auto;

  border-radius: 4px;
  -webkit-box-shadow: 3px 4px 5px 2px rgba(0,0,0,0.34);
  -moz-box-shadow: 3px 4px 5px 2px rgba(0,0,0,0.34);
  box-shadow: 3px 4px 5px 2px rgba(0,0,0,0.34);

  z-index: 9999999;

  &.alert {
    background: greenyellow;
  }
  &.error {
    background: indianred;
  }
  &.input {
    background: cadetblue;
  }

  .message {
    margin: 5px;
    padding: 5px;
    font-weight: bold;
    border-radius: 3px;
    background: rgba(255,255,255,.5);
  }

  .input-spacer {
    padding: 5px;

    input {
      padding: 5px;
    }
  }

  .button-row {
    display: flex;
    flex-basis: 100%;
  }
}
