section {
  border: 1px solid rgb(169, 169, 169);
  margin:0 12px;
  padding:5px;

  &.attached {
    margin-top: -7px;
    border-top:none;
  }

  h2 {
    border-bottom: 1px solid rgb(169, 169, 169);
    text-align: left;
    font-weight: bold;
    padding: 5px 10px;
    margin: 0 -5px;
  }

  &#address {
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-top: -7px;
    font-size: 18px;
  }

  .inner {
    padding:5px;
    word-wrap: normal;
    white-space: normal;

    .line {
      background-color: #fff;
      padding: 3px;
      margin: 4px 0;
    }
  }

  label {
    input[type=radio] {
      visibility: hidden;
    }
  }
}

section.checklist {
  .line {
    img {
      display: inline-block;
      height: 30px;
      margin-right:10px;
    }
  }
}

.map-button {
  font-size: 14px;
  float:right;
  display: flex;
  background-color: #fdca45;
  border:1px solid #fff;
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
  line-height: 20px;
  vertical-align: middle;
  width: 110px;
  margin: 0 auto;
  padding: 5px;

  img {
    height: 16px;
    display: inline-flex;
  }

  div {
    flex-grow: 1;
    display: inline-flex;
    text-align: center;
  }
}

canvas {
  background: #fff;
  border: 1px solid black;
}

// assets stage
.photo-container {
  display:flex;
  flex-flow: row wrap;
}

.photo-box {
  width: 48%;
  margin: 1% auto;
  position:relative;
  border: 1px solid black;
  overflow: hidden;

  @media screen and (min-width: 600px) and (orientation: landscape) {
    width: 31%
  }

  &:before {
    content: '';
    padding-top:82%;
    display:inline-block;
  }

  * {
    position:absolute;
    top:50%;
    transform: translateY(-50%);
    width: 100%;
    display:block;
  }
  img {}
  input {
    top:0;
    right:0;
    bottom:0;
    left:0;
    opacity: 0;
    display:block;
    transform: none;
  }
  .delete-photo {
    top: 15px;
    left: 10px;
    width: 20px;
    height: 20px;
    background: red;
    color:white;
    border-radius: 20px;

    &:before {
      position:absolute;
      content: '\02717';
      font-weight:bold;
      top: 50%;
      left: 50%;
      transform:translateY(-50%) translateX(-50%);
    }
  }

}

.outcome-icon {
  display: inline-block;
  height: 30px;
  margin-right:10px;
}

.planview-header {
  display:flex;
  width: 100%;

  a {
    width: 33%;
  }

  span {
    flex-grow: 1;

    font-size: 30px;
    padding-top: 8px;
    text-align: center;
  }
}

.orderValue .title {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

.orderValue .text {
  text-align: center;
}

.orderValue .deposit {
  font-size: 30px;
  text-align: center;
}

table.paymentsAdd {
  width: 100%;
}

table.paymentsAdd td {
  width: 50%;
  vertical-align: top;
}

input.paymentsAdd {
  width: 100%;
  padding: 5px;
  margin: 0px;
  text-align: center;
  background-color: #33DD33;
  border: 0px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.payments {
  text-align: center;
}

.payments .payment {
  display: flex;
  padding: 5px 10px;
  margin: 5px 0px;
  border: 1px solid #CCC;
  justify-content: center;
  align-items: center;
}

.payments .payment .amount {
  margin-right: 20px;
}

.payments .payment .type {
  margin-right: 20px;
 }

.payments .payment .paymentsDelete {
  display: inline-block;
  padding: 5px;
  width: 100px;
  margin: 0;
  text-align: center;
  background-color: #fa3b4b;
  border: 0;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

input.save {
  width: 100%;
  padding: 5px;
  margin: 0px;
  text-align: center;
  background-color: #3333DD;
  border: 0px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.note {
  width: 70%;

  &.right {
    margin-left: 30%;
  }

  .date {
    font-style: italic;
    color: #888;
  }
  .bubble {
    background: lawngreen;
    border-radius:10px;
    padding: 10px;
    .user {
      font-weight: bold;
    }

    .body {
      color: #444;
      white-space: pre-wrap !important;
      word-wrap: break-word;
    }
  }
  &.right {
    .bubble {
      background: hotpink;
    }
  }
}

.note-form {
  border: none;
  border-top: 1px solid #a9a9a9;
  margin: 12px 0 0 0;
}
